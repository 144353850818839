<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0"> </b-col>

            <!-- Search -->
            <b-col cols="12" md="5">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" :clearable="true" class="d-inline-block mr-1" placeholder="Search..." />
                <add-role ref="add-role" :getRoles="getRoles" :loading="loading"></add-role>
                <b-button variant="primary" @click="addRole()">
                  <span class="text-nowrap">Add New Role</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          class="position-relative"
          :items="roles"
          :filter="filter"
          :filter-included-fields="filterOn"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          :current-page="currentPage"
        >
          <!-- Column: Actions -->
          <template #cell(actions)="row">
            <b-button v-if="row.item.name != 'admin'" size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
              Edit Permissions
            </b-button>
            <b-button v-if="row.item.name != 'admin'" size="sm" @click="duplicate(row.item)" class="mr-1">
              Duplicate
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <b-modal :id="infoModal.id" no-close-on-backdrop :title="infoModal.title" @hidden="resetInfoModal" scrollable size="xl" ok-title="Save" @ok="handleUpdate">
          <b-container class="mt-1">
            <b-form ref="nameRules">
              <b-row>
                <b-col md="3">
              <b-form-group :class="validation ? 'is-valid' : 'is-invalid'" label="Role Name:">
                <validation-provider #default="{ errors }" name="Role Name" rules="required">
                  <b-form-input v-model="newRole" list="input-list" placeholder="Enter Role Name" :state="validation" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="9" style="padding-left: 0px!important">
              <b-form-group :class="validation ? 'is-valid' : 'is-invalid'" label="Role Description:">
                <validation-provider #default="{ errors }" name="Role Description:" rules="required">
                  <b-form-input v-model="newDescription" list="input-list" placeholder="Enter Role Description" :state="validationdes" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" style="margin-bottom: 1rem;">
                  <b-col cols="12">
                    <b-tabs vertical nav-wrapper-class="nav-vertical" fill class="tab-class">
                      <b-tab title="Menu Permissions">
                        <b-table hover responsive :items="p_menu" :fields="fields">
                          <template #cell(description)="data">
                            <span>{{ data.item.description }}</span>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-tab>

                      <b-tab title="Home Page Permissions">
                        <b-table hover responsive :items="p_home" :fields="fields">
                          <template #cell(description)="data">
                            <span>{{ data.item.description }}</span>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-tab>

                      <b-tab title="Tool Page Permissions">
                        <b-table hover responsive :items="p_tool" :fields="fields">
                          <template #cell(description)="data">
                            <span>{{ data.item.description }}</span>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-tab>

                      <b-tab title="Report Page Permissions">
                        <b-table hover responsive :items="p_report" :fields="fields">
                          <template #cell(description)="data">
                            <span>{{ data.item.description }}</span>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-tab>

                      <b-tab title="User Page Permissions">
                        <b-table hover responsive :items="p_user" :fields="fields">
                          <template #cell(description)="data">
                            <span>{{ data.item.description }}</span>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-tab>

                      <b-tab title="Settings Page Permissions">
                        <b-table hover responsive :items="p_settings" :fields="fields">
                          <template #cell(description)="data">
                            <span>{{ data.item.description }}</span>
                          </template>

                          <template #cell()="data">
                            <b-form-checkbox switch size="sm" class="ml-5" :value="data.item" v-model="rolePerms"> </b-form-checkbox>
                          </template>
                        </b-table>
                      </b-tab>
                    </b-tabs>
                  </b-col>
                </b-col>
              </b-row>
            </b-form>
          </b-container>
          {{/*rolePerms*/}}
        </b-modal>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios';
import { BTable } from 'bootstrap-vue';
import store from '@/store';
import settingsStoreModule from '../../settingsStoreModule';
import { onUnmounted } from '@vue/composition-api';
import AddRole from './AddRole.vue';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTable,

    AddRole,
    ToastificationContent,
    BCardText,
    BTabs,
    BTab,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';

    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },
    const tableColumns = [

      {
        key: 'name',
        label: 'Role Name',
        show: true,
        sortable: true,
        class: 'text-center',
        thStyle: 'width: 30%',
      },
      {
        key: 'description',
        label: 'Role Description',
        show: true,
        sortable: true,
        class: 'text-center',
        thStyle: 'width: 30%',
      },
      {
        key: 'actions',
        show: true,
        sortable: false,
        class: 'text-center',
        thStyle: 'width: 30%',
      },
    ];

    return {
      rolePerms: [],
      edit: null,
      newitem: '',
      filter: null,
      filterOn: ['name'],
      roles: [],
      permissions: [],
      currentPage: 1,
      tableColumns,
      totalRows: 0,
      loading: false,
      
      required,
      p_menu: [],
      p_home: [],
      p_tool: [],
      p_report: [],
      p_user: [],
      p_settings: [],


      newDescription:'',
      newRole:'',

      fields: [
        { key: 'Description', sortable: false, class: 'text-left w-75', thClass: 'bg-primary text-white' },
        { key: 'Yes/No', sortable: false, class: 'text-center w-25', thClass: 'bg-primary text-white' },
      ],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    };
  },
  computed: {
    validation() {
      return this.newRole.length > 1 && (this.role === this.newRole || this.roles.filter(e => e.name === this.newRole).length < 1);
    },
    validationdes() {
      return this.newDescription.length > 1;
    },
  },
  methods: {

    handleUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.editPerms(this.role);
    },
    checkFormValidity() {
      const nameValid = this.$refs.nameRules.checkValidity() && this.validation && this.validationdes;
      return nameValid;
    },

    addRole() {
      this.$root.$emit('bv::show::modal', 'addNewRole');
    },

    getRoles() {
      this.loading = true;
      store
        .dispatch('settings/fetchRoles', [])
        .then((res) => {
          this.totalRows = res.data.data.length;
          this.roles = res.data.data;
          this.roles = res.data.data.filter((role) => role.name !== 'supermanager');
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getPermissions() {
      this.loading = true;
      store
        .dispatch('settings/fetchPermissions', [])
        .then((res) => {
          this.loading = false;
          this.permissions = res.data.data;
          res.data.data.forEach((p) => {
            p.group === 'menu' ? this.p_menu.push(p) : '';
            p.group === 'home' ? this.p_home.push(p) : '';
            p.group === 'tool' ? this.p_tool.push(p) : '';

            p.group === 'report' ? this.p_report.push(p) : '';
            p.group === 'user' ? this.p_user.push(p) : '';
            p.group === 'settings' ? this.p_settings.push(p) : '';
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editPerms(role) {

      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide(this.infoModal.id);
      });


      this.loading = true;
      axiosIns
        .put(`roles/${role}`, {
          newRole:this.newRole,
          newDescription:this.newDescription,
          permissions: JSON.parse(JSON.stringify(this.rolePerms)),
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Permissions Updated Successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.getRoles();
          } else {
            this.$swal({
              title: 'Error',
              text: 'Permissions Update Failed. Please try again or contact support.',
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: 'OK',
            });
          }
        });
    },

    info(item, index, button) {
      this.infoModal.title = `Edit Permissions For : ${item.name}`;
      this.infoModal.content = item;
      this.rolePerms = item.permissions;
      this.role = item.name;
      this.newDescription = item.description;
      this.newRole = item.name;

      this.$root.$emit('bv::show::modal', this.infoModal.id, button);
    },

    
    duplicate(item) {

      this.loading = true;
      axiosIns
        .post(`roles/duplicate/`, {
          id:item.id,
          permissions: JSON.parse(JSON.stringify(item.permissions)),
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Permissions Updated Successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.getRoles();
          } else {
            this.$swal({
              title: 'Error',
              text: 'Permissions Update Failed. Please try again or contact support.',
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: 'OK',
            });
          }
        });

      
    },

    resetInfoModal() {
      this.infoModal.title = '';
      this.infoModal.content = '';
    },
  },
  created() {
    this.getRoles();
    this.getPermissions();
  },
};
</script>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}
</style>
